import About from "../about_page/About";
import ContactPage from "../contact/ContactPage";
import Footer from "../landing_page/components/Footer";
import LandingPage from "../landing_page/LandingPage";
import PrivacyPolicy from "../privacy_policy/PrivacyPolicy";
import TermsAndConditions from "../termsAndConditions/TermsAndConditions";
const routes = [
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/about",

    element: (
      <>
        <About />
        <Footer />
      </>
    ),
  },
  {
    path: "/privacypolicy",
    element: (
      <>
        <PrivacyPolicy />
        <Footer />
      </>
    ),
  },
  {
    path: "/termsandconditions",
    element: (
      <>
        <TermsAndConditions />
        <Footer />
      </>
    ),
  },
  {
    path: "/contact-us",
    element: (
      <>
        <ContactPage />
        <Footer />
      </>
    ),
  },
  
];

export default routes;
